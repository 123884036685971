import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRotes from "./AuthenticationRoutes";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import UserRoutes from "./UserRoutes";
import PayGoRoutes from "./PayGoRoutes";
import AdminRoutes from "./AdminRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    LoginRoutes,
    AuthenticationRotes,
    MainRoutes,
    UserRoutes,
    PayGoRoutes,
    AdminRoutes,
  ]);
}
