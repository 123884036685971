// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconHomeEco,
  IconLayoutDashboard,
  IconCooker,
  IconReportMoney,
  IconCreditCard,
  IconFileAnalytics,
  IconSettings,
  IconReceipt2,
} from "@tabler/icons";

// constant
const icons = {
  IconHomeEco,
  IconCreditCard,
  IconLayoutDashboard,
  IconCooker,
  IconReportMoney,
  IconFileAnalytics,
  IconSettings,
  IconReceipt2,
};

const admin = {
  id: "Admin Portal",
  title: <FormattedMessage id="Admin Portal" defaultMessage="Admin Portal" />,
  type: "group",
  children: [
    {
      id: "admin_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/admin-portal/dashboard",
      icon: icons.IconLayoutDashboard,
      breadcrumbs: false,
    },
    {
      id: "admin_aggregation",
      title: <FormattedMessage id="Aggregations" defaultMessage="Aggregations" />,
      type: "item",
      url: "/admin-portal/aggregations",
      icon: icons.IconCooker,
      breadcrumbs: false,
    },
  ],
};

const menuItems = {
  items: [admin],
};

export default menuItems;
