import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Chip, Stack, Typography } from "@mui/material";

// project imports

import useAuth from "hooks/useAuth";

// assets
import { IconHome2 } from "@tabler/icons";

// ==============================|| ADMIN SECTION ||============================== //

const AdminSection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different components and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleNavigate = async () => {
    navigate("/proponent/dashboard");
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Chip
      sx={{
        height: "48px",
        alignItems: "center",
        borderRadius: "27px",
        transition: "all .2s ease-in-out",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.main
            : theme.palette.primary.light,
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.dark.main
            : theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
          borderColor: theme.palette.primary.main,
          background: `${theme.palette.primary.main}!important`,
          color: theme.palette.primary.light,
          "& svg": {
            stroke: theme.palette.primary.light,
          },
        },
        "& .MuiChip-label": {
          lineHeight: 0,
        },
      }}
      label={
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body1"
            color={theme.palette.secondary.main}
            sx={{ marginRight: 1 }}
          >
            Back to Home
          </Typography>
          <IconHome2
            stroke={1.5}
            size="24px"
            color={theme.palette.secondary.main}
          />
        </Stack>
      }
      variant="outlined"
      ref={anchorRef}
      aria-controls={open ? "menu-list-grow" : undefined}
      aria-haspopup="true"
      onClick={handleNavigate}
      color="primary"
    />
  );
};

export default AdminSection;
