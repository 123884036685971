// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconHomeEco,
  IconLayoutDashboard,
  IconCooker,
  IconReportMoney,
  IconCreditCard,
  IconFileAnalytics,
  IconSettings,
  IconReceipt2,
} from "@tabler/icons";

// constant
const icons = {
  IconHomeEco,
  IconCreditCard,
  IconLayoutDashboard,
  IconCooker,
  IconReportMoney,
  IconFileAnalytics,
  IconSettings,
  IconReceipt2,
};

const paygo = {
  id: "PayGo Portal",
  title: <FormattedMessage id="PayGo Portal" defaultMessage="PayGo Portal" />,
  type: "group",
  children: [
    {
      id: "paygo_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/paygo-portal/dashboard",
      icon: icons.IconLayoutDashboard,
      breadcrumbs: false,
    },
    {
      id: "paygo_cookstoves",
      title: <FormattedMessage id="Cookstoves" defaultMessage="Cookstoves" />,
      type: "item",
      url: "/paygo-portal/cookstoves",
      icon: icons.IconCooker,
      breadcrumbs: false,
    },
    {
      id: "paygo_transactions",
      title: (
        <FormattedMessage id="Transactions" defaultMessage="Transactions" />
      ),
      type: "item",
      url: "/paygo-portal/transactions",
      icon: icons.IconReportMoney,
      breadcrumbs: false,
    },
    {
      id: "paygo_payments",
      title: <FormattedMessage id="Payments" defaultMessage="Payments" />,
      type: "item",
      url: "/paygo-portal/payments",
      icon: icons.IconCreditCard,
      breadcrumbs: false,
    },
    {
      id: "paygo_reports",
      title: <FormattedMessage id="Reports" defaultMessage="Reports" />,
      type: "item",
      url: "/paygo-portal/reports",
      icon: icons.IconFileAnalytics,
      breadcrumbs: false,
    },
    {
      id: "paygo_payment_options",
      title: (
        <FormattedMessage
          id="payment-options"
          defaultMessage="Payment Options"
        />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconReceipt2,
      defaultOpen: true,
      children: [
        {
          id: "create_option",
          title: (
            <FormattedMessage
              id="Create Option"
              defaultMessage="Create Option"
            />
          ),
          type: "item",
          url: "/paygo-portal/payment-options/create",
          breadcrumbs: false,
        },
        {
          id: "list_option",
          title: (
            <FormattedMessage id="List Options" defaultMessage="List Options" />
          ),
          type: "item",
          url: "/paygo-portal/payment-options/list",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "paygo_settings",
      title: <FormattedMessage id="Settings" defaultMessage="Settings" />,
      type: "item",
      url: "/paygo-portal/settings",
      icon: icons.IconSettings,
      breadcrumbs: false,
    },
  ],
};

const menuItems = {
  items: [paygo],
};

export default menuItems;
